<template>
  <div class="mx-5 h-100 d-flex flex-column justify-center">
    <ion-img class="w-50 mx-auto" src="/assets/images/not-found.svg" />
    <ion-label class="text-center fw-600 fs-3 mt-3">{{ headerLabel }}</ion-label>
    <ion-text class="text-center fw-400 fs-2 mt-2">{{ subHeaderLabel }}</ion-text>
    <ion-button fill="outline" class="mt-2 w-100 text-capitalize" @click="$emit('callbackAction')">
      {{ actionLabel }}
    </ion-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CompNotFound',
  emits: ['callbackAction'],
  props: {
    headerLabel: {
      type: String,
      default: '',
      required: true
    },
    subHeaderLabel: {
      type: String,
      default: '',
      required: true
    },
    actionLabel: {
      type: String,
      default: '',
      required: true
    }
  }
});
</script>
